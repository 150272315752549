import * as React from 'react';
import { Box } from '@material-ui/core';
import {
    BooleanInput,
    NumberInput,
    SelectInput,
    TextInput,
} from 'react-admin';

import choices from '../choices';
import classes from './styles';

const MonitorProductSpecsInput = () => (
    <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <NumberInput
                label="Tamaño de pantalla (pulgadas)"
                source="specs.inches"
                style={classes.textInputStyles}
            />
            <SelectInput
                choices={choices.monitor.resolutions}
                label="Resolución"
                source="specs.resolution"
                style={classes.textInputStyles}
            />
            <SelectInput
                choices={choices.monitor.refreshRates}
                label="Frecuencia de Refresco"
                source="specs.refresh_rate"
                style={classes.textInputStyles}
            />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" style={classes.boxStyles}>
            <TextInput
                label="Tiempo de Respuesta"
                source="specs.responseTime"
                style={classes.textInputStyles}
            />
            <SelectInput
                choices={choices.monitor.panelTypes}
                label="Tipo de Panel"
                source="specs.panelType"
                style={classes.textInputStyles}
            />
            <BooleanInput
                label="Pantalla Curva"
                source="specs.curvedScreen"
                defaultValue
            />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" style={classes.boxStyles}>
            <SelectInput
                choices={choices.monitor.vesaOptions}
                label="Vesa"
                source="specs.vesa"
                style={classes.textInputStyles}
            />
            <BooleanInput
                label="Nvidia G-Sync"
                source="specs.nvidiaGSync"
                defaultValue
            />
            <BooleanInput
                label="AMD FreeSync"
                source="specs.amdFreeSync"
                defaultValue
            />
        </Box>
        <Box>
            <TextInput
                label="Connections"
                source="specs.connections"
                multiline
                style={classes.textInputStyles}
            />
        </Box>
    </Box>
);

export default MonitorProductSpecsInput;
