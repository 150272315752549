import React from 'react';
import {
    Link,
} from 'react-admin';
import {
    Box, TableRow, TableCell,
} from '@material-ui/core';
import ChangeProductGTINButton from './ChangeProductGTINButton';
import CopyProductInformation from './CopyProductInformation';
import GtinIcon from './GtinIcon';
import ProductBillIdIcon from './ProductBillIdIcon';
import ChangeOrderProductPropertyButton from './ChangeOrderProductPropertyButton';
import { getOrderProductIva } from '../../../helpers/orderHelper';
import cartHelper from '../../../helpers/cartHelper';

const OrderProductTableRow = ({
    orderProduct, editable, orderId, fetchedProduct, buildId,
}) => {
    const { item } = orderProduct;
    const priceWithoutIva = item.chargedPrice
    - getOrderProductIva(true)(orderProduct);

    const formatProductText = ({ code, name, serialNumber }) => {
        let text = `P/N: ${code} ${name}`;
        if (serialNumber) text += ` S/N: ${serialNumber}`;
        return text;
    };
    return (
        <TableRow key={item.productShadow.code}>
            <TableCell>{buildId}</TableCell>
            <TableCell>{item.productShadow.code}</TableCell>
            <TableCell>
                {fetchedProduct ? (
                    <Box display="flex" gap={20}>
                        {!editable ? <GtinIcon gtin={fetchedProduct?.gtin} /> : (
                            <ChangeProductGTINButton record={fetchedProduct} />
                        )}
                    </Box>
                ) : (
                    <GtinIcon gtin={null} />
                )}
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>{item.quantity}</TableCell>
            <TableCell>
                {fetchedProduct ? (
                    <Link to={`/products/${fetchedProduct.id}/show`} underline="none">
                        {item.productShadow.name}
                    </Link>
                ) : (
                    item.productShadow.name
                )}
            </TableCell>
            <TableCell>
                <Box display="flex" gap={20} justifyContent="space-between" alignItems="center">
                    {item.productShadow.provider ?? ' - '}
                    {!editable
                        ? <ProductBillIdIcon productBillId={orderProduct.productBillId} /> : (
                            <ChangeOrderProductPropertyButton
                                record={orderProduct}
                                orderId={orderId}
                                productParameterToSetType="productBillId"
                                editIcon=<ProductBillIdIcon
                                    productBillId={orderProduct.productBillId}
                                />
                            />
                        )}
                </Box>
            </TableCell>
            <TableCell>
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    {orderProduct.serialNumber ?? ' - '}
                    {editable && (
                        <ChangeOrderProductPropertyButton
                            record={orderProduct}
                            orderId={orderId}
                            productParameterToSetType="serialNumber"
                        />
                    )}
                    <CopyProductInformation
                        data={{
                            code: item.productShadow.code,
                            name: item.productShadow.name,
                            serialNumber: orderProduct.serialNumber,
                        }}
                        formatText={formatProductText}
                    />
                </Box>
            </TableCell>
            <TableCell>
                {cartHelper.formatPrice(priceWithoutIva)}
            </TableCell>
            <TableCell style={{ minWidth: 54 }}>
                {item.productShadow.pricing.iva}
                {' '}
                %
            </TableCell>
            <TableCell>{cartHelper.formatPrice(item.chargedPrice)}</TableCell>
            <TableCell>{item.quantity}</TableCell>
            <TableCell>
                {cartHelper.formatPrice((item.chargedPrice ?? 0)
                    * (item.quantity ?? 0))}
            </TableCell>
        </TableRow>
    );
};

export default OrderProductTableRow;
