import * as React from 'react';
import {
    Datagrid,
    DateField,
    EditButton,
    Link,
    List,
    NumberField,
    SelectField,
    ShowButton,
    TextField,
} from 'react-admin';

import OrderFilter from './OrderFilter';
import choices from './choices';
import cartHelper from '../../helpers/cartHelper';
import orderExporter from './orderExporter';
import { dateOptions } from '../../constants/dateOptions';

const BuyerField = ({ record }) => (
    <Link to={`/users/${record.buyer}/show`}>
        {record.buyerData.email}
    </Link>
);

export default (props) => (
    <List
        {...props}
        filters={<OrderFilter />}
        sort={{ field: 'statusDates.new', order: 'DESC' }}
        bulkActionButtons={false}
        exporter={orderExporter}
    >
        <Datagrid>
            <TextField source="code" label="C&oacute;digo" />
            <TextField source="billId" label="N° Factura" />
            <BuyerField label="Comprador" />
            <SelectField choices={choices.status} source="status" />
            <NumberField
                source="totalPrice"
                locales={cartHelper.formaterPesosLocales}
                options={{
                    style: cartHelper.formaterPesosStyle,
                    currency: cartHelper.formaterPesosCurrency,
                }}
            />
            <DateField source="statusDates.new" label="Fecha de Compra Realizada" options={dateOptions} />
            <EditButton basePath="/orders" />
            <ShowButton basePath="/orders" />
        </Datagrid>
    </List>
);
