import * as React from 'react';
import { Box } from '@material-ui/core';
import {
    BooleanField,
    Labeled,
    NumberField,
    SelectField,
    TextField,
} from 'react-admin';

import choices from '../choices';
import classes from './styles';

const MonitorProductSpecsField = ({ record }) => (
    <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Tamaño de pantalla (pulgadas)">
                <NumberField
                    source="specs.inches"
                    record={record}
                />
            </Labeled>
            <Labeled label="Resolución">
                <SelectField
                    source="specs.resolution"
                    choices={choices.monitor.resolutions}
                    record={record}
                />
            </Labeled>
            <Labeled label="Frecuencia de Refresco">
                <SelectField
                    source="specs.refresh_rate"
                    choices={choices.monitor.refreshRates}
                    record={record}
                />
            </Labeled>
        </Box>
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Tiempo de Respuesta">
                <TextField
                    source="specs.responseTime"
                    record={record}
                />
            </Labeled>
            <Labeled label="Tipo de Panel">
                <SelectField
                    source="specs.panelType"
                    choices={choices.monitor.panelTypes}
                    record={record}
                />
            </Labeled>
            <Labeled label="Pantalla Curva">
                <BooleanField
                    source="specs.curvedScreen"
                    record={record}
                />
            </Labeled>
        </Box>
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Vesa">
                <SelectField
                    source="specs.vesa"
                    choices={choices.monitor.vesaOptions}
                    record={record}
                />
            </Labeled>
            <Labeled label="Nvidia G-Sync">
                <BooleanField
                    source="specs.nvidiaGSync"
                    record={record}
                />
            </Labeled>
            <Labeled label="AMD FreeSync">
                <BooleanField
                    source="specs.amdFreeSync"
                    record={record}
                />
            </Labeled>
        </Box>
        <Box>
            <Labeled label="Conexiones">
                <TextField
                    source="specs.connections"
                    record={record}
                    multiline
                />
            </Labeled>
        </Box>
    </Box>
);

export default MonitorProductSpecsField;
