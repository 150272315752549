import { convertToInteger } from '../../helpers/mathHelper';
import { ConfigIds, ConfigsThatAreNotTaxes } from '../../helpers/types';

export const queryConfigs = {
    type: 'getList',
    resource: 'configs',
    payload: { pagination: { page: 1, perPage: 20 } },
};

export const getTaxConfig = (configsData) => configsData
    .filter((config) => !ConfigsThatAreNotTaxes.includes(config.id))
    .reduce((acc, config) => {
        acc[config.id] = config.value;
        return acc;
    }, {});

export const getBrandsConfig = (configsData) => configsData
    .find((config) => config.id === ConfigIds.BRANDS).value
    .map(({ id }) => ({ id, name: id }))
    .sort((a, b) => a.id.localeCompare(b.id));

export const getProvidersConfig = (configsData) => configsData
    .find((config) => config.id === ConfigIds.PROVIDERS).value
    .map(({ id }) => ({ id, name: id }))
    .sort((a, b) => a.id.localeCompare(b.id));

export const parseStrikethroughPrice = (value) => (value === '' ? null : convertToInteger(value));

export const validateImagesLength = (array) => {
    if (!array || !array.length) return 'Se requiere al menos una imagen';
    return undefined;
};
