import React from 'react';
import { Labeled, FunctionField } from 'react-admin';
import { Box } from '@material-ui/core';
import CopyProductInformation from './CopyProductInformation';

const ContactEmailField = ({ email, ...all }) => (
    <Labeled label="Email">
        <Box display="flex" alignItems="center" gap={1}>
            <FunctionField render={() => email || 'No disponible'} {...all} />
            {email && (
                <CopyProductInformation
                    data={email}
                    formatText={(data) => data}
                />
            )}
        </Box>
    </Labeled>
);

export default ContactEmailField;
